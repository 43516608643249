import * as React from "react"
import { Link } from "@StarberryUtils"
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { Row, Container, Col } from 'react-bootstrap';
// styles
const pageStyles = {
  color: "#232129",
  padding: "96px 96px 0px 96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  TextAlign:'center'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  TextAlign: 'center'
  // maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }

// markup
const NotFoundPage = () => {
  return (
    <Layout css={true}>
    <SEO title="404" description="" />
    <div className="sitemap-content sitemap not-found-page">
          <Container>
            <Row>
              <Col
                lg={12}
                md={{
                  span: 12
                }}
              >
    <main style={pageStyles}>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for

        <br />
        <Link className="btn btn-primary" to="/" style={{marginTop:'30px'}}>Go home</Link>
      </p>
    </main>
    </Col>
            </Row>
          </Container>
        </div>

      </Layout>
  )
}

export default NotFoundPage
