/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
// import FooterOptimized from "@Components/Footer/FooterOptimized";

const Layout = ({ children, css, location}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [state, setState] = useState({
    showMenu: false
  })
  const [renderComponent, setRenderComponent] = useState(false);
  const handlerClick = () => {
    // console.log('okkk')
    setState({ ...state, showMenu: !state.showMenu })
  }
  useEffect(() => {
    window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
          setRenderComponent(true)
      }
  }) 
   
}, [])
  return (
    <div className={data.site.siteMetadata === 'Home' ? 'homepage' : 'inner-page'} >
      {/* <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=fx6ysfgnzjcn1fxsmy966w" async={true}></script> */}
      <div className={state.showMenu ? `${css ? 'header-style wrapper open-search-block': 'wrapper open-search-block'}` : `${css ? 'header-style wrapper': 'wrapper'}`}>
        <Header showMenu={state.showMenu} handlerClick={handlerClick} siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        {/* {location?.pathname === '/' ? <FooterOptimized location={location} /> : */}
        {renderComponent &&
        <Footer location={location} />
        }
        {/* } */}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
}

export default Layout
